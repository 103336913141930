.contact_wrap {
    padding: 120px 220px 0 220px;
    padding-bottom: 170px;
    width: calc(100% - 440px);
    height: auto;
    /* height: calc(1560px - 170px); */
    background: #fff;;
}

.contact_inner {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 1000px;
    flex-direction: column;
    justify-self: center;
    gap: 50px;
}

.contact_title_wrap {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    z-index: 2;
}

.contact_title_wrap h3 {
    color: #06F;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.28px;
}

.contact_title_wrap strong {
    color: #1B1B1B;
    font-size: 55px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -0.55px;
}

.contact_content_wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
}

.contact_img_wrap {
    display: flex;
    width: 325px;
    height: 1431px;
    justify-self: center;
    align-items: center;
    background: #BEE1FF;
}

.contact_img_wrap span {
    display: block;
    width: 100%;
    height: 100%;
    background: url('../img/contact/contact_img.png') no-repeat center/contain;
}


.contact_main_wrap {
    display: flex;
    width: 617px;
    height: auto;
    /* height: 1217px; */
    flex-direction: column;
    align-self: center;
    justify-content: flex-start;
    gap: 60px;
}

/* 1. 고객정보 */
.contact_user_info_wrap {
    display: flex;
    width: 100%;
    height: 352px;
    flex-direction: column;
    gap: 25px;
}

.contact_user_title_wrap1 {
    position: relative;
}

.contact_user_title_wrap1 strong {
    color: #4E4E4E;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: -0.28px;
}

.contact_user_title_wrap1 strong:before {
    display: block;
    content: '*';
    position: absolute;
    top: 0px;
    left: -16px;
    color: #F00;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: -0.28px;
}

.type1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.type1 ul {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}

.type1 ul li {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

.type1 ul li span {
    color: #F00;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.2px;
}

.type1 ul li label {
    color: var(--Grey-3, #5C5C5C);
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.2px;
}

.type1 ul li input {
    display: flex;
    padding: 12px 20px;
    width: 245px;
    height: 26px;
    border-radius: 2px;
    border: 1px solid #5C5C5C;
    background: var(--white, #FFF);
    color: #000;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.2px;
    align-items: flex-start;
    gap: 10px;
}

.type1 ul li input::placeholder {
    color: #BFBFBF;
}

.type1 ul li .user_email_wrap {
    display: flex;
    position: relative;
    flex-direction: row;
    gap: 16px;
}

.user_email_wrap div::before {
    content: '@';
    display: block;
    position: absolute;
    /* top: 5px; */
    top: 6px;
    left: 210px;
    color: var(--Grey-3, #5C5C5C);
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -0.3px;
}

input#user_email {
    padding: 12px 20px;
    width: 140px;
    color: #000;
}

input#user_email2 {
    padding: 12px 10px 12px 40px;
    width: 110px;
    color: #000;
}

#user_email_list {
    padding: 12px 15px;
    width: 240px;
    color: #BFBFBF;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.2px;
    -webkit-appearance:none; /* for chrome */
    -moz-appearance:none; /*for firefox*/
    appearance:none;
    background: url('../img/contact/contact_select_arrow.svg') no-repeat center/contain;
    background-size: 18px;
    background-position: calc(100% - 15px);

}
 
#user_email_list::-ms-expand{
    display:none;/*for IE10,11*/
}


/* 2. 문의종류 */
.user_select_wrap {
    display: flex;
    width: 100%;
    height: auto;
    /* height: 150px; */
    flex-direction: column;
    gap: 25.5px;
}

.user_select_wrap {
    position: relative;
}

.contact_user_title_wrap2 strong {
    color: #4E4E4E;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: -0.28px;
}

.contact_user_title_wrap2 strong:before {
    display: block;
    content: '*';
    position: absolute;
    top: 0px;
    left: -16px;
    color: #F00;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: -0.28px;
}

.type2 {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.type2 input[type="checkbox"] {
    margin: 0;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.type2 ul {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.type2 ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.type2 ul:last-of-type li:last-of-type {
    gap: 15px;
}

.type2 label {
    padding-left: 15px;
    color: var(--Grey-3, #5C5C5C);
    font-size: 19px;
    font-weight: 500;
    letter-spacing: -0.2px;
    cursor: pointer;
    word-break: keep-all; /* 단어 단위로 줄바꿈 설정 */
    white-space: normal; /* 일반적인 줄바꿈 동작 */
}

.type2 .user_select_etc_wrap {
    display: flex;
    align-items: center;
    gap: 15px;
}

.type2 .user_select_etc_wrap > div {
    display: flex;
    align-items: center;
}

.type2 #user_select_etc_text {
    padding: 3px 5px;
    width: 208px;
    border: none;
    border-bottom: 1px solid #000;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.2px;
    background: none;
}
.type2 #user_path_search_naver_text {
    padding: 3px 5px;
    width: 220px;
    border: none;
    border-bottom: 1px solid #000;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.2px;
    background: none;
}
.type2 #user_path_search_another_text {
    padding: 3px 5px;
    width: 208px;
    border: none;
    border-bottom: 1px solid #000;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.2px;
    background: none;
}
.type2 #user_else_path_text {
    padding: 3px 5px;
    width: 208px;
    border: none;
    border-bottom: 1px solid #000;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.2px;
    background: none;
}

.type2 #user_select_etc_text::placeholder {
    color: #BFBFBF;
}
.type2 #user_path_search_naver_text::placeholder {
    color: #BFBFBF;
}
.type2 #user_path_search_another_text::placeholder {
    color: #BFBFBF;
}
.type2 #user_else_path_text::placeholder {
    color: #BFBFBF;
}




/* 3. 문의 내용 */
.user_inquiry_wrap {
    display: flex;
    width: 100%;
    height: auto;
    /* height: 228px; */
    flex-direction: column;
    gap: 25px;
}

.contact_user_title_wrap3 {
    position: relative;
}

.contact_user_title_wrap3 strong {
    color: #4E4E4E;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: -0.28px;
}

.contact_user_title_wrap3 strong:before {
    display: block;
    content: '*';
    position: absolute;
    top: 0px;
    left: -16px;
    color: #F00;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: -0.28px;
}

.type3 {
    margin-left: 17px;
    width: 100%;
}

.type3 .user_inquiry_content {
    resize: none;
    padding: 12px 20px;
    width: calc(100% - 57px);
    height: 146px;
    color: #000;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.2px;
}

.type3 .user_inquiry_content::placeholder {
    color: #BFBFBF;
}


/* 4. 알게 된 경로 */
.user_method_wrap {
    display: flex;
    width: 100%;
    height: auto;
    /* height: 157px; */
    flex-direction: column;
    gap: 25px;
}


.contact_user_title_wrap4 {
    position: relative;
}

.contact_user_title_wrap4 strong {
    color: #4E4E4E;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: -0.28px;
}

.contact_user_title_wrap4 strong:before {
    display: block;
    content: '*';
    position: absolute;
    top: 0px;
    left: -16px;
    color: #F00;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: -0.28px;
}

.type4 {
    margin-left: 17px;
    width: 100%;
}

.type4 .user_method_content {
    resize: none;
    padding: 12px 20px;
    width: calc(100% - 57px);
    height: 29px;
    color: #000;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.2px;
}

.user_personal_info_wrap {
    display: flex;
    /* margin-left: 17px; */
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.user_personal_info_wrap #user_personal_info {
    margin: 0;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.user_personal_info_wrap label {
    padding-left: 15px;
    color: var(--Grey-3, #5C5C5C);
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.2px;
    cursor: pointer;
}

.user_personal_info_wrap span {
    color: #FF0000;
}

.user_personal_info_wrap button {
    margin-left: 12px;
    padding: 3px 5px;
    width: 65px;
    border: none;
    border-bottom: 1px solid #000;
    color: #8B8B8B;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.16px;
    white-space: nowrap;
    background: none;
    text-align: center;
    cursor: pointer;
}



/* 5. 문의 버튼 */
.user_submit_wrap {
    display: flex;
    width: 100%;
    height: 90px;
    justify-content: center;
    align-self: center;
}

.user_submit_wrap button {
    display: flex;
    padding: 30px;
    width: 300px;
    height: 90px;
    justify-content: center;
    align-items: center;
    gap: 30px;
    color: #303030;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: -0.28px;
    text-transform: uppercase;
    border: none;
    border-radius: 20px;
    background: #fff;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.20);
    cursor: pointer;
}

.user_submit_wrap button span {
    display: block;
    width: 45px;
    height: 33px;
    background: url('../img/contact/contact_button_icon.svg') no-repeat center/contain;
}


/* 모달영역 - 전문보기 */
.contact_modal_wrap {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.40);;
    z-index: 100;
    justify-content: center;
    align-items: center;
}

.contact_modal_content {
    display: flex;
    background: #fff;
    width: 900px;
    height: 700px;
    flex-direction: column;
}


.contact_modal_header {
    display: flex;
    padding: 15px 10px 15px 40px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #F3F3F3;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
}

.contact_modal_header h4 {
    color: #1B1B1B;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.24px;
}

.contact_modal_header button {
    display: block;
    width: 40px;
    height: 40px;
    border: none;
    background: url('../img/common/close_icon.svg') no-repeat center/contain;
    cursor: pointer;
}

.contact_modal_inner {
    padding: 0 50px;
    overflow-y: scroll;
    overflow-x: hidden;
}




.contact_content_text_wrap {
    display: flex;
    margin-top: 20px;
    padding: 0;
    flex-direction: column;
    box-sizing: border-box;
    white-space: normal;
    word-break: break-all;
}

.contact_content_text_wrap strong {
    font-size: 22px;
    color: #000;
}

.contact_content_text_wrap pre,
.contact_content_text_wrap dl,
.contact_content_text_wrap dt,
.contact_content_text_wrap dd,
.contact_content_text_wrap p {
    color: #4B4B4B;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: -0.18px;
    white-space: pre-wrap;
    word-break: break-all;
}

.contact_content_text_wrap .privacy > div {
    margin-top: 30px;
}

.contact_content_text_wrap .privacy > div.privacy_10 {
    margin-bottom: 50px;
}

.contact_content_text_wrap .privacy strong:after {
    content: '';
    display: block;
    margin-top: 10px;
    margin-bottom: 30px;
    width: 100%;
    height: 1px;
    background-color: #000;
}

.contact_content_text_wrap .privacy_1 dl {
    display: flex;
    margin: 0;
    height: 40px;
    border: 1px solid #f4f4f4;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contact_content_text_wrap .privacy_1 dl:first-of-type {
    padding: 5px 0;
    height: 20px;
    background: #f4f4f4;
}

.contact_content_text_wrap .privacy_1 dl dt {
    flex: 1;
    color: #000;
    text-align: center;
}

.contact_content_text_wrap .privacy_1 dd {
    flex: 1;
    text-align: center;
}

.contact_content_text_wrap .privacy_5 dl {
    display: flex;
    margin: 0;
    padding: 5px 0;
    height: 40px;
    border: 1px solid #f4f4f4;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contact_content_text_wrap .privacy_5 dl:first-of-type {
    height: 20px;
    background: #f4f4f4;
}

.contact_content_text_wrap .privacy_5 dl dt {
    flex: 1;
    color: #000;
    text-align: center;
}

.contact_content_text_wrap .privacy_5 dd {
    flex: 1;
    text-align: center;
}

.contact_content_text_wrap .privacy_8_nav1 {
    border: 2px solid #f5f5f5;
}

.contact_content_text_wrap .privacy_8_nav1 dl {
    display: flex;
    margin: 0;
    flex-direction: row;
    border-bottom: 2px solid #f5f5f5;
}

.contact_content_text_wrap .privacy_8_title {
    margin-top: 20px;
}

.contact_content_text_wrap .privacy_8_nav1 dt {
    padding: 10px 0;
    width: 100px;
    text-align: center;
    background: #f5f5f5;
    color: #000;
}

.contact_content_text_wrap .privacy_8_nav1 dd {
    margin-left: 30px;
    padding-top: 10px;
    color: #000;
}

.privacy_8_nav2_title {
    margin-top: 20px;
}

.contact_content_text_wrap .privacy_8_nav2 {
    border: 2px solid #f5f5f5;
}

.contact_content_text_wrap .privacy_8_nav2 dl {
    display: flex;
    margin: 0;
    height: 50px;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #f5f5f5;
    align-items: center;
}

.contact_content_text_wrap .privacy_8_nav2 dl:first-of-type {
    height: 30px;
    background: #f5f5f5;
}

.contact_content_text_wrap .privacy_8_nav2 dl:last-of-type {
    border-bottom: none;
}

.contact_content_text_wrap .privacy_8_nav2 dt {
    flex: 1;
    text-align: center;
}

.contact_content_text_wrap .privacy_8_nav2 dd {
    margin: 0;
    flex: 1;
    text-align: center;    
}

.type5 ul li{
    width: 33.3333%;
}
.type5 ul li.user_select_etc_wrap-w100{
    width: 100%;
}

@media all and (max-width: 1439px){
    .contact_wrap {
        padding: 60px;
        width: auto;
        height: auto;
        /* height: 1880px; */
    }

    .contact_inner {
        width: auto;
    }

    .contact_title_wrap strong {
        font-size: 45px;
    }

    .contact_content_wrap {
        flex-direction: column;
    }

    .contact_img_wrap {
        width: 100%;
        height: 380px;
        background: #AFE2FF;
    }
    
    .contact_img_wrap span {
        background: url('../img/contact/contact_tablet_img.png') no-repeat center/contain;
    }

    .contact_modal_content {
        width: 730px;
    }

    .contact_content_text_wrap .privacy > div {
        margin-top: 20px;
    }
}



@media all and (max-width: 767px){
    .contact_wrap {
        padding: 30px 25px;
        width: auto;
        height: auto;
        overflow-x: hidden;
    }

    .contact_inner {
        width: auto;
    }

    .contact_title_wrap h3 {
        font-size: 20px;
    }

    .contact_title_wrap strong {
        font-size: 25px;
    }

    .contact_inner {
        gap: 30px;
    }

    .contact_img_wrap {
        width: 100%;
        height: 193px;
    }

    .contact_content_wrap {
        gap: 20px;
    }

    .contact_user_info_wrap {
        height: auto;
        gap: 15px;
    }

    .contact_main_wrap {
        width: 100%;
        gap: 30px;
    }

    .contact_user_title_wrap1 strong,
    .contact_user_title_wrap2 strong,
    .contact_user_title_wrap3 strong,
    .contact_user_title_wrap4 strong {
        padding-left: 20px;
        font-size: 20px;
    }

    .contact_user_title_wrap1 strong:before,
    .contact_user_title_wrap2 strong:before,
    .contact_user_title_wrap3 strong:before,
    .contact_user_title_wrap4 strong:before {
        left: 0;
    }

    .type1 {
        gap: 10px;
    }

    .type1 ul li {
        gap: 5px;
    }

    .type1 ul li label {
        font-size: 16px;
    }

    .type1 ul li input,
    input#user_email,
    input#user_email2,
    #user_email_list {
        padding: 10px 15px;
        width: 250px;
        font-size: 16px;
    }

    input#user_email2 {
        padding-left: 40px;
        width: 225px;
    }

    #user_email_list {
        width: 100%;
        border: 1px solid #000;
    }

    .type1 ul {
        flex-direction: column;
        gap: 10px;
    }

    .type1 ul li .user_email_wrap {
        flex-direction: column;
    }

    .user_email_wrap div::before {
        top: 78px;
        left: 15px;
        font-size: 16px;
    }

    .user_select_wrap {
        height: auto;
        gap: 15px;
    }

    .type2 {
        gap: 0;
    }

    .type2 ul {
        margin: 0 auto;
        /* width: 280px; */
        width: 90%;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .type2 ul li {
        margin-top: 13px;
        width: 50%;
    }

    .type2 ul:first-of-type li:first-of-type,
    .type2 ul:first-of-type li:nth-of-type(2) {
        margin-top: 0;
    }
    
    .type2 input[type="checkbox"] {
        width: 20.25px;
        height: 20.25px;
    }

    .type2 label {
        font-size: 13px;
        flex: 1;
    }

    .type2 .user_select_etc_wrap {
        width: 100%
    }

    .type2 .user_select_etc_wrap > div:first-of-type{
        width: 30%;
    }

    .type2 .user_select_etc_wrap > div {
        width: 70%
    }
    
    .type2 #user_select_etc_text {
        width: 100%;
        font-size: 16px;
    }
    .type2 #user_path_search_naver_text {
        width: 100%;
        font-size: 16px;
    }
    .type2 #user_path_search_another_text {
        width: 100%;
        font-size: 16px;
    }
    .type2 #user_else_path_text {
        width: 100%;
        font-size: 16px;
    }

    .user_inquiry_wrap {
        gap: 15px;
    }

    .type3 {
        margin: 0 auto;
        width: 90%;
        /* width: 280px; */
    }

    .type3 .user_inquiry_content {
        padding: 10px 15px;
        width: 100%;
        height: 117px;
        font-size: 16px;
    }

    .user_method_wrap {
        gap: 15px;
    }

    .type4 {
        margin: 0 auto;
        width: 280px;
    }

    .contact_user_title_wrap4 {
        display: flex;
        width: auto;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .contact_user_title_wrap4 button {
        margin-right: 30px;
        padding: 3px 5px;
        width: 50px;
        border: none;
        border-bottom: 1px solid #000;
        color: #8B8B8B;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.16px;
        white-space: nowrap;
        background: none;
        text-align: center;
        cursor: pointer;
    }

    .type4 .user_method_content {
        width: 240px;
        height: 20px;
        font-size: 16px;
    }

    .user_personal_info_wrap {
        margin: 0;
        margin-top: 25px;
        justify-content: center;
    }

    .user_personal_info_wrap #user_personal_info {
        width: 20.25px;
        height: 20.25px;
    }

    .user_personal_info_wrap label {
        padding-left: 10px;
        font-size: 12px;
    }

    .user_submit_wrap button {
        margin-top: 15px;
        padding: 24.3px;
        width: 234px;
        height: 72.9px;
        font-size: 22.68px;
    }

    .user_submit_wrap button span {
        width: 36.45px;
        height: 26.73px;
    }
    


    .contact_modal_content {
        width: calc(100vw - 50px);
        height: 500px;
    }

    .contact_modal_inner {
        padding: 13px 20px;
    }
    
    .contact_content_text_wrap {
        margin: 0;
    }

    .contact_content_text_wrap pre,
    .contact_content_text_wrap dl,
    .contact_content_text_wrap dt,
    .contact_content_text_wrap dd,
    .contact_content_text_wrap p {
        font-size: 12px;
    }

    .contact_modal_header {
        padding: 9px 10px 9px 13px;
    }

    .contact_modal_header h4 {
        font-size: 14px;
    }

    .contact_content_text_wrap strong {
        font-size: 18px;
    }

    .contact_content_text_wrap .privacy_1 dl {
        height: 50px;
    }
    
    .contact_content_text_wrap .privacy strong:after {
        margin-bottom: 20px;
    }

    .contact_content_text_wrap .privacy > div.privacy_0 {
        margin-top: 0px;
    }

    .contact_content_text_wrap .privacy_8_nav2 dl {
        height: 60px;
    }

    .contact_content_text_wrap .privacy_8_nav1 dd {
        margin-left: 20px;
    }

}
