@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

body {
    font-family: 'Pretendard';
    padding-top: 120px;
    overflow-x: hidden;
    width: 100%;
}

.app {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app_content {
    width: 100%;
}