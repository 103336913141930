.company_wrap {
    padding: 120px 220px 270px 220px;
    width: calc(100% - 440px);
    height: calc(800px - 270px);
    background: #E9E9E9;;
}

.company_inner {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 1000px;
    flex-direction: column;
    justify-self: center;
}

.company_title_wrap {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    z-index: 2;
}

.company_title_wrap h3 {
    color: #06F;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.28px;
}

.company_title_wrap strong {
    color: #1B1B1B;
    font-size: 55px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -0.55px;
}

.company_middle_title {
    position: relative;
    margin-top: 45px;
    z-index: 2;
}

.company_middle_title h4 {
    color: #06F;
    font-size: 28px;
    font-weight: 800;
    line-height: 1.25;
    letter-spacing: -0.28px;
}

.company_content {
    display: flex;
    position: relative;
    margin-top: 30px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
    z-index: 2;
}

.company_content p {
    color: #4E4E4E;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.44;
    letter-spacing: -0.18px;
}

.company_background {
    display: block;
    position: absolute;
    width: 584px;
    height: 623px;
    top: -32px;
    right: -80px;
    background: url('../img/company/company_background_1.png') no-repeat center/contain;
    z-index: 0;
}

.company_background2 {
    display: block;
    position: absolute;
    width: 143.46px;
    height: 38.64px;
    top: 282px;
    right: 284px;
    background: url('../img/company/company_background_2.svg') no-repeat center/contain;
    z-index: 0;
}



/* 테블릿버전 */
@media all and (max-width: 1439px){
    .company_wrap {
        padding: 60px;
        width: auto;
        height: 920px;
    }

    .company_inner {
        width: auto;
    }

    .company_title_wrap strong {
        font-size: 45px;
    }

    .company_middle_background {
        display: block;
        position: relative;
        margin-top: 36px;
        width: 100%;
        height: 350px;
        background-color: #fff;
        background-image: url('../img/company/company_background_1.png');
        background-repeat: no-repeat;
        background-position: right;
    }

    .company_middle_background span {
        display: block;
        position: absolute;
        width: 182px;
        height: 49px;
        top: calc(50% - 25px);
        left: calc(50% - 250px);
        background: url('../img/company/company_background_2.svg') no-repeat center/contain;
    }


    .company_background {
        display: none;
    }

    .company_background2 {
        display: none;
    }
}







@media all and (max-width: 767px){
    .company_wrap {
        padding: 30px 25px;
        height: 720px;
    }

    .company_title_wrap {
        gap: 5px;
    }

    .company_title_wrap h3 {
        font-size: 20px;
    }

    .company_title_wrap strong {
        font-size: 24px;
    }

    .company_middle_background {
        margin-top: 30px;
        width: 100%;
        height: 180px;
        background-size: 277px;
    }

    .company_middle_background span {
        width: 92.422px;
        height: 24.883px;
        top: calc(50% - 12px);
        left: calc(50% - 130px);
    }

    .company_middle_title {
        margin-top: 30px;
    }

    .company_middle_title h4 {
        font-size: 18px;
    }

    .company_content {
        gap: 20px;
    }

    .company_content p {
        font-size: 16px;
    }
}