.process_wrap {
    padding: 120px 220px 90px 220px;
    width: calc(100% - 440px);
    background: #ffcf3f;
}

.process_inner {
    display: flex;
    margin: 0 auto;
    width: 1000px;
    flex-direction: column;
    justify-self: center;
}

.process_title_wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

.process_title_wrap h3 {
    color: #06f;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.28px;
}

.process_title_wrap strong {
    color: #1b1b1b;
    font-size: 55px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -0.55px;
}

.process_produce_wrap {
    margin-top: 30px;
}

.process_produce_wrap p {
    color: #4e4e4e;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.28px;
}

.process_content_wrap {
    display: flex;
    margin-top: 50px;
    flex-direction: row;
    justify-content: space-between;
}

.process_box {
    display: flex;
    position: relative;
    width: 150px;
    height: 240px;
    background: #fff;
    flex-direction: column;
    border-radius: 18px;
    justify-content: center;
    align-items: center;
}

.process_box_frame {
    display: flex;
    position: absolute;
    background: #424b5a;
    width: 35px;
    height: 35px;
    top: 25px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.process_box_frame span {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
}

.process_box_img_frame {
    display: flex;
    width: 100px;
    height: 100px;
    background: url("../img/process/procee_oval.svg") no-repeat center/contain;
    justify-content: center;
    align-items: center;
}

.process_1 span {
    display: block;
    width: 43.13px;
    height: 45.83px;
    background: url("../img/process/process_1.png") no-repeat center/contain;
}

.process_2 span {
    display: block;
    width: 47px;
    height: 44px;
    background: url("../img/process/process_2.png") no-repeat center/contain;
}

.process_3 span {
    display: block;
    width: 42px;
    height: 42px;
    background: url("../img/process/process_3.png") no-repeat center/contain;
}

.process_4 span {
    display: block;
    width: 45px;
    height: 42px;
    background: url("../img/process/process_4.png") no-repeat center/contain;
}

.process_5 span {
    display: block;
    width: 60px;
    height: 37px;
    background: url("../img/process/process_5.png") no-repeat center/contain;
}

.process_6 span {
    display: block;
    width: 42px;
    height: 41px;
    background: url("../img/process/process_6.png") no-repeat center/contain;
}

.prcess_box_title {
    display: flex;
    margin-top: 18px;
    height: 50px;
}

.prcess_box_title span {
    color: #4e4e4e;
    text-align: center;
    font-size: 22px;
    font-weight: 800;
    line-height: 1.18;
    letter-spacing: -1px;
}

.process_arrow {
    display: block;
    position: absolute;
    width: 34px;
    height: 27px;
    top: calc(50% - 14px);
    right: -28px;
    background: url("../img/process/process_arrow.svg") no-repeat center/contain;
    z-index: 1;
}

.process_footer_wrap {
    width: 100%;
    display: flex;
    margin-top: 30px;
    margin-left: 15px;
    flex-direction: row;
    gap: 10px;
}
.process_footer_title_wrap {
    width: 100%;
}
.process_footer_title_wrap ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.process_footer_title_list {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.process_footer_title_list div {
    display: flex;
    width: 23px;
    height: 23px;
    background: #424b5a;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.process_footer_title_list div span {
    margin-top: 2px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.process_list_txt {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.process_footer_title_list strong {
    color: #4e4e4e;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.22px;
    width: 20%;
}
.process_footer_title_list p {
    width: 80%;
    text-align: left;
    display: flex;
}
.process_footer_title_list p span {
    display: block;
    margin-right: 8px;
}

.process_footer_produce_wrap {
}

.process_footer_produce_wrap ul {
    display: flex;
    flex-direction: column;
}

.process_footer_produce_wrap span {
    color: #4e4e4e;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.89;
    letter-spacing: -0.18px;
}

/* 테블릿버전 */
@media all and (max-width: 1439px) {
    .process_wrap {
        padding: 60px 0 100px;
        width: auto;
    }

    .process_title_wrap strong {
        font-size: 45px;
    }

    .process_content_wrap {
        margin-left: 30px;
        flex-wrap: wrap;
    }

    .process_box {
        max-width: 214px;
        min-width: 150px;
        max-height: 342px;
        min-height: 240px;
    }

    .process_arrow {
        max-width: 54px;
        max-height: 43px;
    }

    .process_type_line {
        flex-basis: 100%;
    }

    .process_box.process_type4,
    .process_box.process_type5,
    .process_box.process_type6 {
        margin-top: 50px;
    }

    .process_arrow {
        right: -42px;
    }

    .process_type3 .process_arrow {
        right: 55px;
        top: auto;
        bottom: -38px;
        transform: rotate(90deg);
    }

    .process_type4 .process_arrow,
    .process_type5 .process_arrow {
        transform: rotate(180deg);
    }

    .process_footer_wrap {
        margin-top: 50px;
        margin-left: 30px;
    }

    .process_footer_title_wrap {
        margin: 0 auto;
    }

    .process_footer_title_wrap ul {
        gap: 20px;
    }

    .process_footer_title_list {
        align-items: flex-start;
    }
    .process_footer_title_list strong {
        width: 100%;
    }
    .process_footer_title_list p {
        margin-top: 5px;
        color: #4e4e4e;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.18px;
        width: 100%;
    }
    .process_footer_title_list p span {
        display: none;
    }
}

@media all and (max-width: 767px) {
    .process_wrap {
        padding: 30px 25px 80px;
        width: auto;
        overflow-x: hidden;
    }

    .process_inner {
        width: auto;
    }

    .process_title_wrap {
        gap: 5px;
    }

    .process_title_wrap h3 {
        font-size: 20px;
    }

    .process_title_wrap strong {
        font-size: 24px;
    }

    .process_produce_wrap {
        margin-top: 20px;
    }

    .process_produce_wrap p {
        font-size: 18px;
        word-wrap: break-word;
        white-space: nowrap;
    }

    .process_content_wrap {
        margin: 0 auto;
        margin-top: 30px;
        gap: 20px;
    }

    .process_box {
        max-width: none;
        width: 130px;
        min-width: 0;
        max-height: none;
        height: 190px;
        min-height: 0;
    }

    .process_box_frame {
        width: 29px;
        height: 29px;
        top: 16px;
    }

    .process_box_img_frame {
        width: 82px;
        height: 82px;
    }

    .prcess_box_title {
        margin-top: 10px;
        height: 40px;
    }

    .prcess_box_title span {
        font-size: 18px;
    }

    .process_1 span {
        width: 43px;
        height: 45px;
    }

    .process_2 span {
        width: 44.422px;
        height: 41.444px;
    }

    .process_4 span {
        width: 42px;
        height: 39px;
    }

    .process_5 span {
        width: 50px;
        height: 31px;
    }

    .process_6 span {
        width: 38px;
        height: 37px;
    }

    .process_box.process_type4,
    .process_box.process_type5,
    .process_box.process_type6 {
        margin-top: 0;
    }

    .process_type1 .process_arrow {
        right: -28px;
    }

    .process_type2 .process_arrow {
        right: 47px;
        top: auto;
        bottom: -23px;
        transform: rotate(90deg);
    }

    .process_type3 .process_arrow {
        right: auto;
        bottom: auto;
        transform: rotate(180deg);
        left: -28px;
    }

    .process_type3 .process_arrow {
        right: auto;
        bottom: auto;
        transform: rotate(180deg);
        left: -28px;
    }

    .process_type4 .process_arrow {
        right: auto;
        top: auto;
        bottom: -23px;
        transform: rotate(90deg);
    }

    .process_type4 .process_arrow {
        right: auto;
        top: auto;
        bottom: -23px;
        transform: rotate(90deg);
    }

    .process_type5 .process_arrow {
        right: -28px;
        transform: rotate(0);
    }

    .process_footer_wrap {
        margin: 0 auto;
        margin-top: 30px;
        width: 277px;
    }
    .process_footer_title_wrap ul {
        gap: 15px;
    }
    .process_footer_title_list > span {
        width: 251px;
    }

    .process_footer_title_list strong {
        font-size: 18px;
    }

    .process_footer_title_list p {
        font-size: 14px;
    }

    .process_footer_title_list div span {
        margin: 0;
    }
}
