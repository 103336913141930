.about_wrap {
    padding: 120px 220px 220px 220px;
    width: calc(100% - 440px);
    height: calc(1440px - 220px);
    background: #F6F6F6;
}

.about_inner {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 1000px;
    flex-direction: column;
    justify-self: center;
}

.about_title_wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

.about_title_wrap h3 {
    color: #06F;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.28px;
}

.about_title_wrap strong {
    color: #1B1B1B;
    font-size: 55px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -0.55px;
}

.about_content_wrap {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
}

.about_logo_content_wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.about_logo {
    display: flex;
    width: 460px;
    height: 460px;
    background: #fff;
    justify-content: center;
    align-items: center;
}

.about_logo span {
    display: block;
    width: 280px;
    height: 280px;
    background: url('../img/about/about_logo.png') no-repeat center/contain;
}

.about_corporation_produce {
    display: flex;
    width: 480px;
    flex-direction: column;
    justify-content: flex-start;
}

.about_corporation_produce strong {
    color: #06F;
    font-size: 28px;
    font-weight: 800;
    line-height: 1.25;
    letter-spacing: -0.28px;
}

.about_corporation_produce span {
    color: #4E4E4E;
    font-size: 28px;
    font-weight: 800;
    line-height: 35px;
    letter-spacing: -0.28px;
}

.about_corporation_produce p {
    margin-top: 22px;
    color: #4E4E4E;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: -0.18px;
}

.about_box_wrap {
    margin-top: 50px;
    width: 100%;
}

.about_box_wrap ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;   
}

.about_box_wrap ul li {
    display: flex;
    padding: 40px 20px;
    width: 150px;
    height: 184px;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.about_box_wrap .about_box_img_wrap {
    display: flex;
    width: 60px;
    height: 60px;
    background: url('../img/about/about_top_background.svg') no-repeat center/contain;
    justify-content: center;
    align-items: center;
}

.about_box_wrap .about_box_img_wrap.about_icon_bottom_wrap {
    background: url('../img/about/about_bottom_background.svg') no-repeat center/contain;
}

.about_box_wrap .about_icon_1 span {
    display: block;
    width: 30px;
    height: 31.5px;
    background: url('../img/about/about_icon_1.svg') no-repeat center/contain;
}

.about_box_wrap .about_icon_2 span {
    display: block;
    width: 35px;
    height: 36.458px;
    background: url('../img/about/about_icon_2.svg') no-repeat center/contain;
}

.about_box_wrap .about_icon_3 span {
    display: block;
    width: 24px;
    height: 33.6px;
    background: url('../img/about/about_icon_3.svg') no-repeat center/contain;
}

.about_box_wrap .about_icon_4 span {
    display: block;
    width: 32px;
    height: 33.461px;
    background: url('../img/about/about_icon_4.svg') no-repeat center/contain;
}

.about_box_wrap .about_icon_5 span {
    display: block;
    width: 35px;
    height: 29.714px;
    background: url('../img/about/about_icon_5.svg') no-repeat center/contain;
}

.about_box_wrap .about_icon_6 span {
    display: block;
    width: 36px;
    height: 36px;
    background: url('../img/about/about_icon_6.svg') no-repeat center/contain;
}

.about_box_wrap .about_icon_7 span {
    display: block;
    width: 30px;
    height: 33px;
    background: url('../img/about/about_icon_7.svg') no-repeat center/contain;
}

.about_box_wrap .about_icon_8 span {
    display: block;
    width: 30px;
    height: 37.438px;
    background: url('../img/about/about_icon_8.svg') no-repeat center/contain;
}
.about_box_wrap .about_icon_9 span {
    display: block;
    width: 32px;
    height: 33.865px;
    background: url('../img/about/about_icon_9.svg') no-repeat center/contain;
}
.about_box_wrap .about_icon_10 span {
    display: block;
    width: 30px;
    height: 33px;
    background: url('../img/about/about_icon_10.svg') no-repeat center/contain;
}

.about_box_wrap strong {
    color: #4E4E4E;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.09;
    letter-spacing: -0.5px;
}

.about_box_wrap span {
    color: #8B8B8B;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.31;
    letter-spacing: -1px;
}



@media all and (max-width: 1439px) {
    .about_wrap {
        padding: 60px;
        width: auto;
        /* height: 1940px; */
    }

    .about_inner {
        width: auto;
    }

    .about_content_wrap {
        gap: 50px;
    }

    .about_title_wrap strong {
        font-size: 45px;
    }

    .about_logo_content_wrap {
        flex-direction: column;
        gap: 50px;
    }

    .about_logo {
        margin: 0 auto;
        width: 100%;
        height: 300px;
    }

    .about_logo span {
        width: 230px;
        height: 164px;
    }

    .about_corporation_produce {
        width: 100%;
    }

    .about_box_wrap {
        margin: 0;
    }
}



@media all and (max-width: 767px){
    .about_wrap {
        padding: 30px 25px;
        height: 1400px;
        overflow-x: hidden;
    }

    .about_content_wrap {
        margin-top: 30px;
    }

    .about_title_wrap {
        gap: 5px;
    }

    .about_title_wrap h3 {
        font-size: 20px;
    }

    .about_title_wrap strong {
        font-size: 24px;
    }

    .about_logo_content_wrap {
        gap: 30px;
    }

    .about_logo {
        height: 165px;
    }

    .about_logo span {
        width: 128px;
        height: 91px;
    }

    .about_corporation_produce strong,
    .about_corporation_produce span {
        font-size: 18px;
        line-height: 1.3;
    }

    .about_corporation_produce p {
        margin-top: 20px;
        font-size: 16px;
    }

    .about_box_wrap {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .about_box_wrap ul {
        flex-direction: column;
    }

    .about_box_wrap ul li {
        /* padding: 0; */
        padding: 25.263px 12.632px;
        width: 94.7px;
        height: 66.5px;
    }

    .about_box_wrap .about_box_img_wrap {
        width: 38px;
        height: 38px;
    }
    
    .about_box_wrap strong {
        font-size: 13px;
    }

    .about_box_wrap .about_icon_1 span {
        width: 19px;
        height: 20px;
    }

    .about_box_wrap .about_icon_2 span {
        width: 22.105px;
        height: 23.026px;
    }

    .about_box_wrap .about_icon_3 span {
        width: 15.158px;
        height: 21.221px;
    }

    .about_box_wrap .about_icon_4 span {
        width: 20.211px;
        height: 21.133px;
    }

    .about_box_wrap .about_icon_5 span {
        width: 22.105px;
        height: 18.767px;
    }

    .about_box_wrap .about_icon_6 span {
        width: 22.105px;
        height: 23.289px;
    }

    .about_box_wrap .about_icon_7 span {
        width: 19px;
        height: 20.842px;
    }

    .about_box_wrap .about_icon_8 span {
        width: 19px;
        height: 23.645px;
    }

    .about_box_wrap .about_icon_9 span {
        width: 20.211px;
        height: 21.388px;
    }

    .about_box_wrap .about_icon_10 span {
        width: 20.211px;
        height: 21.388px;
    }

    
}