.portfolio_wrap {
    padding: 120px 0 100px;
    /* height: 645px; */
    width: 70%;
    margin: 0 auto;
}

.portfolio_inner {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
}

.portfolio_inner .swiper-nav {

    width: 100%;

}


.portfolio_inner .swiper-nav .custom-next-btn2 {
    right: -7%;
}

.portfolio_inner .swiper-nav .custom-prev-btn2 {
    left: -7%;
}

.portfolio_title_wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

.portfolio_title_wrap h3 {
    color: #06F;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.28px;
}

.portfolio_title_wrap strong {
    color: #1B1B1B;
    font-size: 55px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -0.55px;
}


.portfolio_box_wrap {
    position: relative;
    margin-top: 50px;
    /* width: 140%; */
}

.portfolio_box {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding: 0px 30px;
    height: 260px;
    background: #F4F4F4;
    border-radius: 40px;
}

.portfolio_logo_wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.portfolio_logo_wrap img {
    max-height: 50px;
}

.portfolio_1 .portfolio_logo_wrap span {
    display: block;
    width: 119px;
    height: 24px;
    background: url('../img/portfolio/portfolio_1.png') no-repeat center/contain;
}

.portfolio_2 .portfolio_logo_wrap span {
    display: block;
    width: 47px;
    height: 26px;
    background: url('../img/portfolio/portfolio_2.svg') no-repeat center/contain;
}

.portfolio_3 .portfolio_logo_wrap span {
    display: block;
    width: 36px;
    height: 26px;
    background: url('../img/portfolio/portfolio_3.svg') no-repeat center/contain;
}

.portfolio_4 .portfolio_logo_wrap span {
    display: block;
    width: 107px;
    height: 23px;
    background: url('../img/portfolio/portfolio_4.png') no-repeat center/contain;
}

.portfolio_5 .portfolio_logo_wrap span {
    display: block;
    width: 39px;
    height: 27px;
    background: url('../img/portfolio/portfolio_5.svg') no-repeat center/contain;
}

.portfolio_6 .portfolio_logo_wrap span {
    display: block;
    width: 39px;
    height: 27px;
    background: url('../img/portfolio/portfolio_5.svg') no-repeat center/contain;
}

.portfolio_7 .portfolio_logo_wrap span {
    display: block;
    width: 36px;
    height: 26px;
    background: url('../img/portfolio/portfolio_3.svg') no-repeat center/contain;
}

.portfolio_8 .portfolio_logo_wrap span {
    display: block;
    width: 36px;
    height: 26px;
    background: url('../img/portfolio/portfolio_3.svg') no-repeat center/contain;
}

.portfolio_logo_wrap p {
    margin-left: 6px;
    color: #06F;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: -0.6px;
}

.portfolio_box_title_wrap {
    margin-top: 20px;
}

.portfolio_box_title_wrap br {
    display: none;
}

.pickme br {
    display: block;
}

.portfolio_box_title_wrap strong {
    color: #4E4E4E;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -0.32px;
    white-space: normal;
    /* 기본적으로 자동 개행 */
    word-break: keep-all;
    /* 단어 단위로 끊기 */
}

.linkfiLogo,
.leasepickLogo {
    height: 30px;
}

.portfolio_produce_wrap {
    margin-top: 10px;
}

.portfolio_produce_wrap span {
    color: #5C5C5C;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: -0.36px;
    white-space: normal;
    /* 기본적으로 자동 개행 */
    word-break: keep-all;
    /* 단어 단위로 끊기 */
}

.portfolio_detail_wrap {
    margin-top: 15px;
}

.portfolio_detail_wrap button {
    width: 129px;
    height: 35px;
    color: #8B8B8B;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: -0.16px;
    border: 1px solid #BABABA;
    border-radius: 20px;
    background-color: #fff;
    cursor: pointer;
}

.detail_arrow {
    display: inline-block;
    margin-left: 4px;
    width: 19px;
    height: 19px;
    vertical-align: -4px;
    background: url('../img/portfolio/portfolio_arrow.svg') no-repeat center/contain;
}

.swiper {
    position: relative;
}
.portfolio_box_slide{
    padding-bottom: 60px;
}
.swiper-scrollbar {
    /* width: 1000px !important; */
    height: var(--swiper-scrollbar-size, 10px) !important;
    /* bottom: var(--swiper-scrollbar-bottom, -60px) !important; */
    /* border-radius: 0 !important; */
}

/* .swiper-scrollbar {
    position: absolute;
    bottom: 0;               
    width: 100%;            
    height: 10px !important;
    background: rgba(0,0,0,0.1);
} */
/* .swiper-scrollbar-drag {
    border-radius: 0 !important;
    transition-timing-function: linear;
} */

.portfolio_box_slide .swiper-wrapper {
    transition-timing-function: linear;
}


/* 모달영역 */
.portfolio_modal_wrap {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.40);
    ;
    z-index: 100;
    justify-content: center;
    align-items: center;
}

.portfolio_modal_content {
    display: flex;
    background: #fff;
    width: 900px;
    height: 700px;
    flex-direction: column;
    max-width: 90%;
}

.portfolio_modal_header {
    display: flex;
    padding: 15px 10px 15px 40px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #F3F3F3;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
}

.portfolio_modal_header h4 {
    color: #1B1B1B;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.24px;
}

.portfolio_modal_header button {
    display: block;
    width: 40px;
    height: 40px;
    border: none;
    background: url('../img/common/close_icon.svg') no-repeat center/contain;
    cursor: pointer;
}

.portfolio_modal_inner {
    /* padding: 0 50px; */
    overflow-y: scroll;
    overflow-x: hidden;
}


/* 1번모달 - 허브블록 제어 모바일앱 */
.portfolio_content_img_wrap {
    display: flex;
    width: 95%;
    padding: 28px;
    box-sizing: border-box;
    border: 1px solid #D6D6D6;
    background: #EBEBEB;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.10) inset;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: 30px;

}

.portfolio_content_img_wrap img {
    width: 100%;

}

.appImgBox,
.appImgBox2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.appImgBox2 img {
    width: 48%;
    margin: 0 1% 1%;
}

.appImgBox img {
    width: 48%;
    margin: 0 1% 1%;
}



.appImgBox img:last-child {
    width: 70%;
}

.aiChatImgBox {
    display: flex;
    justify-content: space-between;
}

.aiChatImgBox img {
    height: 430px;
    object-fit: contain;
}

.modal_type1 .portfolio_content_img1 {
    display: block;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 362.25px;
    height: 172.5px;
    background: url('../img/modal/modal1_img1.png') no-repeat center/contain;
}

.modal_type1 span {
    margin: 10px !important;

}

.modal_type1 .portfolio_content_img2 {
    display: block;
    margin-left: 10px;
    margin-bottom: 10px;
    width: 362.25px;
    height: 172.5px;
    background: url('../img/modal/modal1_img2.png') no-repeat center/contain;
}

.modal_type1 .portfolio_content_img3 {
    display: block;
    margin-right: 10px;
    margin-top: 10px;
    width: 362.25px;
    height: 172.5px;
    background: url('../img/modal/modal1_img3.png') no-repeat center/contain;
}

.modal_type1 .portfolio_content_img4 {
    display: block;
    margin-left: 10px;
    margin-top: 10px;
    width: 362.25px;
    height: 172.5px;
    background: url('../img/modal/modal1_img4.png') no-repeat center/contain;
}

/* 2번모달 - 재고관리 솔루션 개발 */
.modal_type2 .portfolio_content_img1 {
    display: block;
    margin-bottom: 11.5px;
    width: 612px;
    height: 366px;
    background: url('../img/modal/modal2_img1.png') no-repeat center/contain;
}

.modal_type2 .portfolio_content_img2 {
    display: block;
    margin-top: 11.5px;
    width: 612px;
    height: 400px;
    background: url('../img/modal/modal2_img2.png') no-repeat center/contain;
}

/* 3번모달 - 경진대회 수상 로그 */
.modal_type3 .portfolio_content_img1 {
    display: block;
    margin-bottom: 11.5px;
    width: 465.284px;
    height: 372px;
    background: url('../img/modal/modal3_img1.png') no-repeat center/contain;
}

/* 4번모달 - 최적발주량 */
.modal_type4 {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modal_type4 .portfolio_content_img1 {
    display: block;
    width: 611.512px;
    height: 327.877px;
    background: url('../img/modal/modal4_img1.png') no-repeat center/contain;
}

.modal_type4 .portfolio_content_img2 {
    display: block;
    width: 611.512px;
    height: 306.171px;
    background: url('../img/modal/modal4_img2.png') no-repeat center/contain;
}

.modal_type4 strong {
    color: #000;
    font-size: 20.569px;
    font-weight: 500;
    letter-spacing: -0.206px;
}

/* 5번모달 - Datastudio 리포팅 */
.modal_type5 {
    display: flex;
    flex-direction: column;
    gap: 23px;
}

.modal_type5 .portfolio_content_img1 {
    display: block;
    width: 590.411px;
    height: 406.982px;
    background: url('../img/modal/modal5_img1.png') no-repeat center/contain;
}

.modal_type5 .portfolio_content_img2 {
    display: block;
    width: 590.411px;
    height: 372.589px;
    background: url('../img/modal/modal5_img2.png') no-repeat center/contain;
}

/* 6번모달 - 성능테스트 */
.modal_type6 {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modal_type6 .portfolio_content_img1 {
    display: block;
    width: 743.552px;
    height: 116px;
    background: url('../img/modal/modal6_img1.png') no-repeat center/contain;
}

.modal_type6 .portfolio_content_img2 {
    display: block;
    width: 603.346px;
    height: 344.769px;
    background: url('../img/modal/modal6_img2.png') no-repeat center/contain;
}

.modal_type6 strong {
    color: #000;
    font-size: 20.686px;
    font-weight: 500;
    letter-spacing: -0.207px;
}

/* 7번모달 - 데이터분석 */
.modal_type7 {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modal_type7 .portfolio_content_img1 {
    display: block;
    width: 643.552px;
    height: 500px;
    background: url('../img/modal/modal7_img1.png') no-repeat center/contain;
}

/* 8번모달 - 데이터분석 */
.modal_type8 {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modal_type8 .portfolio_content_img1 {
    display: block;
    width: 643.552px;
    height: 500px;
    background: url('../img/modal/modal8_img1.png') no-repeat center/contain;
}



.portfolio_content_text_wrap {
    display: flex;
    margin: 20px 0;
    flex-direction: column;
    gap: 30px;
    padding: 28px;
}

.portfolio_content_text_wrap ul {
    margin-left: 10px;
}

.portfolio_content_text_wrap ul li {
    color: #5C5C5C;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: -0.36px;
    list-style: inside;
}


/* 스크롤바 폭도 JS로 계산하던 것을 단순화 */
/* .swiper-scrollbar { */
    /* width: auto !important; */
    /* or 원하는 px / % */
/* } */

@media all and (max-width: 1500px) {
    .portfolio_box_title_wrap strong {
        font-size: 25px;
    }
    .portfolio_produce_wrap span{
        font-size: 16px;
    }
}

/* 테블릿버전 */
@media all and (max-width: 1439px) {
    .portfolio_wrap {
        padding-top: 95px;
        width: 80%;
    }

    .portfolio_title_wrap strong {
        font-size: 45px;
    }



    .portfolio_box_wrap {
        margin-left: 0;
    }

    /* .swiper-scrollbar {
        width: auto;
    } */


    .portfolio_modal_inner {
        /* padding: 10px; */
    }



    .modal_type1 .portfolio_content_img1,
    .modal_type1 .portfolio_content_img2,
    .modal_type1 .portfolio_content_img3,
    .modal_type1 .portfolio_content_img4 {
        margin: 0px;
        width: 315px;
        height: 150px;
    }

    .modal_type6 .portfolio_content_img1 {
        width: 100%;
    }

    .modal_type7 .portfolio_content_img7 {
        height: 300px;
    }

    .modal_type8 .portfolio_content_img7 {
        height: 300px;
    }


}

@media all and (max-width: 1250px) {
    .portfolio_wrap {
        width: 85%;

    }
}

@media all and (max-width: 1000px) {
    .portfolio_wrap {
        width: 65%;

    }

    .portfolio_box {
        justify-content: center;
    }

    .portfolio_box_title_wrap br {
        display: block;
    }

    .portfolio_content_img_wrap {
        width: 97%;
    }

    .portfolio_box_title_wrap strong {
        font-size: 25px;
    }

    .portfolio_box {
        height: 310px;
    }

    .portfolio_produce_wrap span {
        font-size: 16px;
    }

    .pickme strong {
        font-size: 20px;
    }

    .aiChatImgBox img {
        height: 330px;
    }

}


@media all and (max-width: 767px) {
    .portfolio_wrap {
        width: 75%;

    }
    .swiper-scrollbar-drag{
        width: 50px !important;
    }

    .portfolio_modal_content .appImgBox img {
        width: 98%;
    }

    .aiChatImgBox img {
        height: 40vw;
    }

    .portfolio_title_wrap {
        gap: 5px;
    }

    .portfolio_title_wrap h3 {
        font-size: 20px;
    }

    .portfolio_title_wrap strong {
        font-size: 25px;
    }

    .portfolio_box_title_wrap strong {
        font-size: 20px;
    }

    .portfolio_box_wrap {
        margin-top: 30px;
    }

    .portfolio_box {
        padding: 30px 20px;
        height: 140px;

    }

    .portfolio_box_content_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .portfolio_box_title_wrap {
        margin: 0;
        text-align: center;
    }



    .portfolio_box_title_wrap strong {
        font-size: 20px;
    }

    .portfolio_1 .portfolio_logo_wrap span {
        width: 99px;
        height: 20px;
    }


    .portfolio_produce_wrap {
        display: none;
    }

    .portfolio_logo_wrap p {
        font-size: 16px;
    }

    .portfolio_detail_wrap {
        margin: 0;
    }

    .portfolio_detail_wrap button {
        width: 110px;
        height: 30px;
        font-size: 14px;
    }

    .detail_arrow {
        width: 12.883px;
        height: 18.387px;
    }

    .portfolio_modal_header {
        padding: 9px 13px;
    }

    .portfolio_modal_header h4 {
        font-size: 14px;
    }

    .portfolio_content_img_wrap {
        padding: 18px 20px;
        width: calc(100% - 40px);
        flex-direction: column;
    }

    .modal_type1 .portfolio_content_img1,
    .modal_type1 .portfolio_content_img2,
    .modal_type1 .portfolio_content_img3,
    .modal_type1 .portfolio_content_img4 {
        width: 260px;
        height: 124px;
    }

    .modal_type1 .portfolio_content_img4 {
        height: 148px;
    }

    .portfolio_content_text_wrap ul li {
        font-size: 14px;
    }

    .portfolio_content_text_wrap {
        gap: 20px;
    }


    .modal_type2 .portfolio_content_img1 {
        margin: 0;
        width: 260px;
        height: 156px;
    }

    .modal_type2 .portfolio_content_img2 {
        margin: 0;
        width: 260px;
        height: 170px;
    }


    .modal_type3 .portfolio_content_img1 {
        width: 100%;
        height: 300px;
    }


    .modal_type4 .portfolio_content_img1 {
        width: 260px;
        height: 140px;
    }

    .modal_type4 .portfolio_content_img2 {
        width: 260px;
        height: 130px;
    }

    .modal_type4 strong {
        font-size: 12px;
    }

    .modal_type5 .portfolio_content_img1 {
        width: 260px;
        height: 180px;
    }

    .modal_type5 .portfolio_content_img2 {
        width: 260px;
        height: 165px;
    }

    .modal_type6 .portfolio_content_img1 {
        width: 260px;
        height: 50px;
    }

    .modal_type6 .portfolio_content_img2 {
        width: 260px;
        height: 150px;
    }

    .modal_type6 strong {
        font-size: 12px;
    }

    .modal_type7 .portfolio_content_img1 {
        width: 100%;
        height: 370px;
    }

    .modal_type8 .portfolio_content_img1 {
        width: 100%;
        height: 370px;
    }

    .portfolio_modal_content {
        height: 500px;
    }

    .pickme strong {
        font-size: 16px;
    }
}

@media all and (max-width: 500px) {
    .portfolio_wrap {
        width: 85%;

    }

    .portfolio_box_title_wrap strong {
        font-size: 16px;
    }

    .portfolio_logo_wrap p {
        font-size: 12px;
    }



    .portfolio_logo_wrap img {
        max-height: auto;
        max-width: 70%;
        height: auto;

    }

    .pickme strong {
        font-size: 12px;
    }

    .portfolio_logo_wrap {
        justify-content: center;
    }
}