/* Service_1 */
.service_wrap {
    padding: 120px 0 100px;
    /* height: 645px; */
    width: 70%;
    margin: 0 auto;
}

.service_inner {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
}

.service_title_wrap {
    display: flex;
    width: 550px;
    flex-direction: column;
    gap: 10px;
}

.service_title_wrap h3 {
    color: #06f;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.28px;
}

.service_title_wrap strong {
    color: var(--Text-black, #1b1b1b);
    font-size: 55px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -0.55px;
}

.service_produce_wrap {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    gap: 10px;
}

.service_produce_wrap p {
    color: #4e4e4e;
    font-size: 28px;
    font-weight: 800;
    line-height: 1.25;
    letter-spacing: -0.28px;
}

.service_produce_wrap span {
    color: #4e4e4e;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: -0.18px;
}

.service_content_wrap {
    display: flex;
    margin-top: 50px;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.service_content_wrap .service_box {
    display: flex;
    width: 24%;
    height: 335px;
    padding: 30px 32px 30px;
    box-sizing: border-box;
    border-radius: 30px;
    background: #777;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.service_box.active {
    background: #ffcf3f;
}

.box_frame {
    display: flex;
    width: 100px;
    height: 100px;
    background: url("../img/service/box_frame.svg") no-repeat center/contain;
    justify-content: center;
    align-items: center;
}

.box_frame_1 {
    display: block;
    width: 55px;
    height: 55px;
    background: url("../img/service/box_ai.svg") no-repeat center/contain;
}
.box_frame_2 {
    display: block;
    width: 29px;
    height: 51px;
    background: url("../img/service/box_application.svg") no-repeat center/contain;
}
.box_frame_3 {
    display: block;
    width: 43px;
    height: 43px;
    background: url("../img/service/box_web.svg") no-repeat center/contain;
}

.box_frame_4 {
    display: block;
    width: 48px;
    height: 43px;
    background: url("../img/service/box_data.svg") no-repeat center/contain;
}

.box_content {
    display: flex;
    width: 100%;
    margin-top: 19px;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    gap: 10px;
}

.box_content strong,
.box_content span {
    color: #1b1b1b;
    text-align: center;
}

.box_content strong {
    color: #fff;
    text-align: center;
    font-size: 26px;
    font-weight: 700;
}

.service_box.active .box_content strong {
    color: #1b1b1b;
}

.box_content span {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: -0.15px;
}

.service_box.active .box_content span {
    color: #4e4e4e;
}

.box_content ul {
}
.box_content li {
    display: flex;
    align-items: center;
    width: 100%;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: -0.15px;
}
.box_content li::before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-right: 10px;
    background-color: #fff;
    border-radius: 50%;
}
.service_box.active .box_content li {
    color: #4e4e4e;
}
.service_box.active .box_content li::before {
    background-color: #4e4e4e;
}
/* Service_2 */

.service2_wrap {
    padding-top: 120px;
    width: 100%;
    /* padding-bottom: 160px; */
    /* height: calc(800px - 280px); */

    background: url("../img/service/service2_background.png") 50% 50% no-repeat;
    background-size: cover;
    background-color: #C8CBCF;
}

.service2_inner {
    display: flex;
    margin: 0 auto;
    width: 70%;
    flex-direction: column;
    justify-self: center;
}

.service2_title_wrap {
    display: flex;
    width: 500px;
    flex-direction: column;
    gap: 10px;
}

.service2_title_wrap h3 {
    color: #06f;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.28px;
}

.service2_title_wrap strong {
    color: var(--Text-black, #1b1b1b);
    font-size: 55px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -0.55px;
}

.service2_produce_wrap {
    margin-top: 30px;
}

.service2_produce_wrap p {
    color: #4e4e4e;
    font-size: 28px;
    font-weight: 800;
    line-height: 1.25;
    letter-spacing: -0.28px;
}

.service2_content_wrap,
.list_frame_inner {
    display: flex;
    flex-direction: column;
}

.service2_content_wrap {
    position: relative;
    margin: 50px 0 100px;
    /* gap: 100px; */
}

.list_frame_inner {
    gap: 25px;
    width: 70%;
}

.list_frame,
.list_frame_inner ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.list_frame {
    justify-content: space-between;
}

.list_frame_title_wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    margin-bottom: 2%;
}

.list_frame h4 {
    color: #4e4e4e;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -0.4px;
}

.list_frame ul li {
    display: flex;
    width: 23%;
    height: 50px;
    background: #fff;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
}

.list_frame ul li span {
    color: #4e4e4e;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.22px;
}

.list_frame:first-of-type:after {
    content: "";
    display: block;
    width: 100%;
    margin: 50px 0 60px;
    height: 1px;
    background: #848484;
}

/* 테블릿버전 */
@media all and (max-width: 1439px) {
    /* Service1 */
    .service_wrap {
        padding-top: 95px;
        width: 80%;
        /* height: 865px; */
    }

    .service_title_wrap strong {
        color: var(--Text-black, #1b1b1b);
        font-size: 45px;
        font-weight: 700;
        line-height: 1.4;
        letter-spacing: -0.45px;
    }

    .service_content_wrap {
        margin-top: 50px;
        /* flex-direction: column; */
        /* align-items: flex-start; */
    }

    /* .service_content_wrap span {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.333;
        letter-spacing: -0.18px;
    } */

    .service_content_wrap .service_box {
        /* margin-top: 20px;
        width: calc(100% - 20px);
        height: 180px;
        flex-direction: row;
        gap: 40px; */
        padding: 30px 27px 20px;
    }

    /* .service_content_wrap .service_box:first-of-type {
        margin-top: 0;
        padding: 30px 27px 20px;
    } */

    .box_content {
        width: 90%;
        margin: 10px auto 0;
        /* margin-top: 0;
        align-items: flex-start; */
    }

    .box_content span {
        width: 420px;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: -0.18px;
        text-align: start;
    }

    /* Service2 */
    .service2_wrap {
        
        padding-top: 60px;
        padding-bottom: 90px;
        width: auto;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .service2_content_wrap{
        margin-top: 73px;
    }

    .service2_title_wrap strong {
        font-size: 45px;
    }

    .list_frame {
        position: relative;
        flex-direction: column;
        /* gap: 18px; */
    }

    .list_frame_inner {
        gap: 10px;
        width: 100%;
    }

    .list_frame_title_wrap {
        justify-content: start;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 0;
    }
    .list_frame h4{
        margin-bottom: 15px;
    }
    .list_frame h4 {
        font-size: 28px;
    }
    .list_frame ul li {
        /* width: 150px; */
        height: 60px;
    }

    .list_frame ul li span {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.2px;
    }

    .list_frame:first-of-type:after {
        /* display: none; */
        margin: 35px 0;
    }

    .service_list_line {
        display: block;
        position: absolute;
        height: 1px;
        background: #848484;
    }
    .box_content li {
        font-size: 14px;
    }
    .service2_inner {
        width: 80%;
    }
}
@media all and (max-width: 1250px) {
    .service_wrap {
        width: 85%;
    }

    .box_content {
        width: 88%;
    }

    .service_content_wrap .service_box {
        padding: 20px 13px 10px;
        height: 304px;
    }
    .box_content li {
        font-size: 13px;
    }
    .list_frame ul li {
        width: 24%;
        margin-bottom: 1%;
    }
    .service2_inner {
        width: 85%;
    }
}
@media all and (max-width: 1000px) {
    .service_wrap,
    .service2_inner {
        width: 65%;
    }

    .service_content_wrap .service_box {
        width: 49%;
        align-items: normal;
        /* height: 150px; */
        min-height: 320px;
        height: 37vw;
        gap: 10px;
        margin-bottom: 2%;
    }
    .box_frame {
        margin: 0 auto;
    }
    .box_content li {
        font-size: 15px;
    }
    .service2_title_wrap strong {
        font-size: 40px;
    }
    .list_frame ul li span {
        font-size: 20px;
    }
}
@media all and (max-width: 765px) {
    .service_wrap,
    .service2_inner {
        width: 75%;
    }
    .list_frame ul li span {
        font-size: 15px;
    }
    .list_frame ul li {
        min-height: 50px;
        height: 8vw;
    }
}

@media all and (max-width: 500px) {
    .service_wrap,
    .service2_inner {
        width: 85%;
    }
    .service_wrap {
        /* height: 760px; */
        overflow-x: hidden;
    }

    .service_produce_wrap {
        margin-top: 20px;
        line-height: 1.5;
    }

    .service_content_wrap {
        align-items: center;
        flex-wrap: wrap;
    }

    .service_title_wrap h3 {
        font-size: 20px;
    }

    .service_title_wrap strong {
        font-size: 24px;
    }

    .service_produce_wrap p {
        font-size: 18px;
    }

    .service_produce_wrap span {
        height: auto;
        font-size: 16px;
    }
    .box_content {
        width: 28vw;
        max-width: 70%;
        min-width: 60%;
    }

    .box_content strong {
        font-size: 30px;
    }
    .box_content li {
        font-size: 16px;
    }
    .box_content span {
        width: 210px;
        height: 65px;
        font-size: 14px;
    }

    .box_frame {
        margin-top: 40px;
        margin: 0 auto;
        /* width: 60px; */
        /* height: 60px; */
    }

    .box_frame_1 {
        width: 60px;
        height: 54px;
    }

    .box_frame_2 {
        width: 48px;
        height: 48px;
    }

    .box_frame_3 {
        width: 45px;
        height: 45px;
    }

    .service2_wrap {
        padding: 30px 25px 25px 30px;
        overflow-x: hidden;
        background-image: url("../img/service/m_service2_background.png");
        background-size: contain;
        background-position: 50% 100%;
    }

    .service2_title_wrap {
        gap: 5px;
    }

    .service2_title_wrap h3 {
        font-size: 20px;
    }

    .service2_title_wrap strong {
        font-size: 24px;
    }

    .service2_produce_wrap {
        margin-top: 20px;
    }

    .service2_produce_wrap p {
        height: auto;
        font-size: 18px;
    }

    .service2_content_wrap {
        margin: 55px 0;
        align-items: center;
        /* gap: 40px; */
    }

    .list_frame h4 {
        font-size: 22px;
    }

    .list_frame,
    .list_frame_inner ul {
        width: 100%;
        flex-wrap: wrap;
        margin-bottom: 30px;
    }

    .list_frame ul li {
        width: 48.5%;
        height: 11vw;
        border-radius: 10px;
        margin-bottom: 3.5%;
    }

    .list_frame ul li span {
        font-size: 16px;
    }
}
@media all and (max-width: 765px) {
    .service_content_wrap .service_box {
        min-height: 295px;
        height: 38vw;
    }
    .box_content li {
        font-size: 12px;
    }
    .box_content {
        width: 70%;
    }
    .service_title_wrap {
        width: 100%;
    }
}

@media all and (max-width: 500px) {
    .service_wrap {
        padding-bottom: 60px;
    }
    .service_content {
        margin-top: 25px;
    }
    .list_frame:first-of-type:after {
        display: none;
        /* margin: 20px 0; */
    }
    .box_frame {
        width: 30%;
    }
    .box_content {
        margin-top: 0;
        width: 60%;
        max-width: 100%;
        justify-content: flex-start;
    }

    .service_content_wrap .service_box {
        width: 100%;
        flex-direction: row;
        align-items: center;
        padding: 0px 13px;
        min-height: 190px;
        margin-bottom: 3%;
    }
    .box_content strong {
        position: relative;
        right: 10px;
        font-size: 25px;
        text-align: left;
    }
    .box_content li {
        font-size: 13px;
    }
    .box_frame_1 {
        width: 52px;
        height: 50px;
    }
    .box_frame_3 {
        width: 38px;
        height: 43px;
    }
}
