html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, abbr, address, cite, code, del, dfn, em, img,
ins, kbd, q, s, samp, small, strong, sub, sup, var, b, u, i,
dl, dd, dt, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby, section, summary,
time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font-size: inherit; background:transparent;}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {display: block;}
nav, ul, ol, li { list-style: none;}
a {margin:0; padding:0; font-size:100%; vertical-align:baseline; background:transparent; text-decoration: none;}
blockquote, q { quotes: none;} /* 인용문에 기호 속성 */
q:before, q:after {content: ''; content: none;}
blockquote:before, blockquote:after,
q:before, q:after { content:''; content:none;}
table { border-collapse: collapse; border-spacing: 0;}
ins {background-color:#ff9; color:#000; text-decoration:none;}
mark {background-color:#ff9; color:#000; font-style:italic; font-weight:bold;}
del {text-decoration: line-through;}
abbr[title], dfn[title] {border-bottom:1px dotted; cursor:help;}
hr {display:block; height:1px; border:0; border-top:1px solid #cccccc; margin:1em 0; padding:0;}
input, select {vertical-align:middle;}
strong {font-weight: bolder;}
code,
kbd,
samp {font-family: monospace, monospace; font-size: 1em;}
small {font-size: 80%;}
sub,
sup {font-size: 75%; line-height: 0; position: relative; vertical-align: baseline;}
img {border-style: none;}
button,input {overflow: visible;}
button, select {text-transform: none;}
button, [type="button"], [type="reset"], [type="submit"] {-webkit-appearance: button;}
button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {border-style: none; padding: 0;}
button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {border-style: none; padding: 0;}
button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {}
legend {box-sizing: border-box; color: inherit; display: table; max-width: 100%; padding: 0; white-space: normal;}
progress {vertical-align: baseline;}
textarea {overflow: auto;}
[type="checkbox"], [type="radio"] {box-sizing: border-box; padding: 0;}
[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {height: auto;}
[type="search"] {-webkit-appearance: textfield; outline-offset: -2px;}
[type="search"]::-webkit-search-decoration {-webkit-appearance: none;}
::-webkit-file-upload-button {-webkit-appearance: button; font: inherit;}
details {display: block;}
summary {display: list-item;}
template {display: none;}
.blind {border: 0; clip: rect(0 0 0 0); clip-path: inset(50%); width: 1px; height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; white-space: nowrap; }
