.header_wrap {
    display: flex;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    justify-content: center;
    align-items: center;
    z-index: 60;
}

.header_inner {

}

.header_web {
    display: flex;
    width: 1000px;
    height: 120px;
    justify-content: space-between;
    align-items: center;
}

.header_tablet_phone {
    display: none;
}

.header_logo_wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.header_logo_wrap button {
    display: flex;
    background: none;
    border: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.header_logo_wrap img {
    display: block;
    width: 150px;
    height: 31px;
    flex-shrink: 0;
}

.header_gnb_wrap {

}

.header_gnb_wrap ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 56px;
}

.header_gnb_wrap ul li {
    display: block;
    padding: 0 3px 3px 0;
}




/* 테블릿 버전 */
@media all and (max-width: 1439px) {
    .header_web {
        display: none;
    }

    .header_tablet_phone {
        display: flex;
        width: calc(100VW - 100px);
        height: 120px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .header_tablet_phone .header_logo_wrap {
        margin-left: 30px;
    }

    .header_tablet_phone .header_logo_wrap img {
        width: 166px;
        height: 34px;
    }

    .header_tablet_phone .menu_bar {
        display: flex;
        width: 60px;
        height: 60px;
        justify-content: center;
        align-items: center;
        border: none;
        background: url('../img/common/tablet_menu.svg') no-repeat center/contain;
        cursor: pointer;
    }

    .header_tablet_phone .header_nav_wrap {
        position: absolute;
        display: none;
        top: 0;
        right: 0;
        background: #fff;
        animation: nav_off .5s backwards;
    }

    .header_tablet_phone .header_nav_wrap.on {
        display: block;
        animation: nav_on .5s backwards;
    }

    .header_tablet_phone .header_nav_wrap button {
        width: 450px;
        height: 120px;
        border: none;
        background: #fff;
        box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.10);
        color: #4E4E4E;
        text-align: center;
        font-size: 35px;
        font-weight: 600;
        letter-spacing: -1px;
        cursor: pointer;
    }

    .header_tablet_phone .header_nav_wrap button.header_nav_close_btn {
        display: block;
        position: absolute;
        top: 0;
        right: 450px;
        width: 60px;
        height: 60px;
        background: url('../img/common/menu_close_icon.svg') no-repeat center/contain;
    }

    .header_tablet_phone .header_nav_wrap button:hover:not(.header_home, .header_nav_close_btn) {
        color: #fff;
        background: #0066FF;
    }

    .header_tablet_phone .header_nav_wrap button.header_home {
        display: flex;
        color: #06F;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8.71px;
    }

    .header_tablet_phone .header_nav_wrap button.header_home span {
        display: block;
        width: 33.286px;
        height: 29.125px;
        background: url('../img/common/nav_home.svg') no-repeat center/contain;
    }

}


/* 휴대폰 버전 */
@media all and (max-width: 767px) {
    body {
        padding-top: 90px;
    }

    .header_tablet_phone {
        width: 100VW;
        height: 90px;
    }

    .header_tablet_phone .header_logo_wrap {
        margin-left: 22px;
    }

    .header_tablet_phone .header_logo_wrap img {
        width: 107px;
        height: 22px;
    }

    .header_tablet_phone .header_nav_wrap button {
        width: 240px;
        height: 64px;
        font-size: 18.721px;
        font-weight: 600;
        letter-spacing: -0.535px;
    }

    .header_tablet_phone .header_nav_wrap button.header_nav_close_btn {
        width: 32px;
        height: 32px;
        right: 240px;
    }

    .header_tablet_phone .header_nav_wrap button.header_home span {
        width: 17px;
        height: 15px;
    }

    .header_tablet_phone .menu_bar {
        margin-right: 22px;
        width: 30px;
        height: 30px;
    }
}



@keyframes nav_on {
    from {display: none; transform: translateX(450px);}
    to {display: block; transform: translateX(0);}
}

@keyframes nav_off {
    from {display: block; transform: translateX(0);}
    to {display: none; transform: translateX(700px);}
}
