.footer_wrap {
    padding: 120px 220px 0 220px;
    /* padding-bottom: 210px; */
    width: calc(100% - 440px);
    height: calc(440px - 120px);
    background: #FC3;
}

.footer_inner {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 1000px;
    flex-direction: column;
    justify-self: center;
    gap: 50px;
}

.footer_title_wrap {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.footer_title_wrap span {
    display: block;
    width: 175px;
    height: 35px;
    background: url('../img/footer/footer_logo.svg') no-repeat center/contain;
}

.footer_menu_wrap {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.footer_contact_address {
    display: flex;
    flex-direction: column;
}

.footer_contact_address span {
    color: #4E4E4E;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.16px;
}

.footer_contact_wrap div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.footer_contact_wrap img{
    width: 30px;
    margin-left: 10px;
    cursor: pointer;
}
.footer_contact_phone_wrap,
.footer_contact_email_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer_contact_wrap .footer_contact_phone {
    display: block;
    width: 18.39px;
    height: 18.39px;
    background: url('../img/footer/footer_phone_icon.svg') no-repeat center/contain;
}

.footer_contact_wrap .footer_contact_email {
    display: block;
    width: 18.667px;
    height: 14px;
    background: url('../img/footer/footer_email_icon.svg') no-repeat center/contain;
}

.footer_contact_wrap a {
    margin-left: 5px;
    color: #4E4E4E;
    text-align: right;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: -0.16px;
}

.footer_border {
    display: block;
    width: 100%;
    height: 2px;
    border: 1px solid var(--Dark, #0A142F);
    opacity: 0.06;
    background: var(--Dark, #0A142F);
}

.footer_copyright_wrap {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footer_copyright {
    color: var(--Text-black, #1B1B1B);
    font-size: 16px;
    font-weight: 400;
}

.footer_madeby_wrap {
    display: flex;
    flex-direction: row;
}

.footer_madeby_text {
    margin-top: 4px;
    color: #4E4E4E;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.16px;
}

.keyonbit_logo {
    display: block;
    margin-left: 6px;
    width: 72px;
    height: 18.903px;
    background: url('../img/footer/keyonbit_logo.svg') no-repeat center/contain;
}




@media all and (max-width: 1439px) {
    .footer_wrap {
        padding: 60px;
        width: auto;
        height: 260px;
    }

    .footer_inner {
        width: auto;
    }
}


@media all and (max-width: 767px) {
    .footer_wrap {
        padding: 80px 25px;
        width: auto;
    }

    .footer_inner {
        width: auto;
        gap: 20px;
    }

    .footer_title_wrap {
        justify-content: center;
    }

    .footer_title_wrap span {
        width: 150px;
        height: 30px;
    }

    .footer_contact_address span {
        font-size: 12px;
    }

    .footer_menu_wrap {
        flex-direction: column;
    }

    .footer_contact_address {
        align-items: center;
    }

    .footer_contact_wrap div {
        align-items: center;
        gap: 5px;
    }

    .footer_contact_wrap {
        margin-top: 8px;
    }

    .footer_contact_wrap a {
        font-size: 14px;
    }

    .footer_contact_wrap .footer_contact_phone {
        width: 14px;
        height: 14px;
    }

    .footer_contact_wrap .footer_contact_email {
        width: 16px;
        height: 12px;
    }

    .footer_border {
        margin: 10px 0;
    }

    .footer_copyright_wrap {
        flex-direction: column;
        gap: 13px;
    }

    .footer_copyright {
        font-size: 12px;
    }

}
