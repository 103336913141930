.hero_inner {
    position: relative;
}

.hero_inner .swiper-nav {
    max-width: 900px;
    width: 100%;
    padding: 0 17px;

}



.hero_wrap {
    position: relative;
    width: 100%;
    background: #fff;
}

.hero_content {
    position: relative;
    width: 100%;
    height: 350px;
    flex-shrink: 0;
    border-radius: 30px;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    content: none !important;
}

.swiper-button-prev,
.swiper-button-next {
    display: block;
    width: 14px !important;
    height: 30px !important;
}

.swiper-button-prev {
    background: url('../img/hero/swiper-prev.svg') no-repeat center/contain;
}

.swiper-button-next {
    background: url('../img/hero/swiper-next.svg') no-repeat center/contain;
}

.hero_content_inner {
    display: inline-flex;
    position: absolute;
    padding-bottom: 0;
    top: 88px;
    left: 90px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
}

.hero_content_inner h3 {
    color: #1B1B1B;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -1px;
    text-transform: capitalize;
}

.hero_content_inner h3 span {
    color: #06F;
}

.hero_content_inner>span {
    color: #4E4E4E;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -1px;
}

.hero_frame_wrap {
    position: absolute;
}

.hero_frame_wrap span {
    display: block;
}

.hero_frame_1 {
    top: 62px;
    right: 95px;
}

.hero_frame_1 span {
    width: 225px;
    height: 225px;
    background: url('../img/hero/frame_1.svg') no-repeat center/contain;
}

.hero_frame_2 {
    top: 60px;
    right: 95px;
}

.hero_frame_2 span {
    width: 225px;
    height: 225px;
    background: url('../img/hero/frame_2.svg') no-repeat center/contain;
}

.hero_frame_3 {
    top: 57px;
    right: 98px;
}

.hero_frame_3 span {
    width: 222px;
    height: 236px;
    background: url('../img/hero/frame_3.svg') no-repeat center/contain;
}

.hero_frame_4 {
    top: 62px;
    right: 95px;
}

.hero_frame_4 span {
    width: 225px;
    height: 225px;
    background: url('../img/hero/frame_4.svg') no-repeat center/contain;
}

.hero_frame_5 {
    top: 62px;
    right: 95px;
}

.hero_frame_5 span {
    width: 225px;
    height: 225px;
    background: url('../img/hero/frame_5.svg') no-repeat center/contain;
}

.hero_frame_6 {
    top: 62px;
    right: 75px;
}

.hero_frame_6 span {
    width: 225px;
    height: 225px;
    background: url('../img/hero/frame_6.svg') no-repeat center/contain;
}

.hero_wrap .swiper-scrollbar {
    display: none !important;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -48px !important;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 6px !important;
}

.swiper-pagination-bullet {
    width: 15px !important;
    height: 15px !important;
}




/* 테블릿 버전 */
@media all and (max-width: 1439px) {
    .hero_content {
        display: flex;
        width: 100%;
        height: 550px;
        border-radius: 0;
        justify-content: center;
        align-items: center;
    }

    .hero_content_inner {
        position: unset;
        align-items: center;
        gap: 20px;
    }

    .hero_frame_wrap {
        position: unset;
    }

    .hero_frame_wrap span {
        width: 225px;
        height: 225px;
    }

    .hero_content_inner>span {
        text-align: center;
    }

    .hero_content_inner h3 {
        text-align: center;
    }


}



@media all and (max-width: 767px) {

    .hero_content {
        height: 350px;
    }

    .hero_frame_wrap span {
        width: 150px;
        height: 150px;
    }

    .hero_content_inner h3 {
        font-size: 26px;
        font-weight: 700;
        letter-spacing: -1px;
    }

    .hero_content_inner>span {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.2;
    }

    .swiper-pagination-bullet {
        width: 10px !important;
        height: 10px !important;
    }

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: -40px !important;
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 10px !important;
        height: 22px !important;
    }

 
}